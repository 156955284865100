import {ReactNode, useState} from 'react';
import {Button} from 'reactstrap';
import {FaEye, FaEyeSlash} from 'react-icons/fa';

import {CopyLink} from '../CopyLink';

import {Props} from './types';
import './styles.css';

const CONCEALED_STRING = <span style={{opacity: 0.8}}>{'●'.repeat(6)}</span>;

const copyableValue = (value: ReactNode | string | number): value is string | number =>
  typeof value === 'string' || typeof value === 'number';

export const Property = ({
  label,
  value,
  disableCopy = false,
  concealed = false,
  hideLabel = false,
  hideValue = false,
}: Props) => {
  const [isConcealed, setIsConcealed] = useState(concealed);
  const displayValue = isConcealed ? CONCEALED_STRING : value;

  const copyFragment = (!disableCopy && copyableValue(value)) ? <CopyLink value={value}/> : null;

  const buttonFragment = (
    <Button color="link" onClick={() => setIsConcealed(!isConcealed)}>{isConcealed ? (
      <FaEye style={{marginBottom: '0.125em'}} />
    ) : (
      <FaEyeSlash style={{marginBottom: '0.125em'}} />
    )}</Button>
  );

  return (
    <div className="Property">
      {!hideLabel && (
        <p className="Property_label">
          {label}
          {concealed && buttonFragment}
          {copyFragment}
        </p>
      )}
      {!hideValue && (
        <p className="Property_value" title={(hideLabel && typeof label === 'string') ? label : undefined}>
          {displayValue}
          {(concealed && hideLabel) && buttonFragment}
          {hideLabel ? copyFragment : null}
        </p>
      )}
    </div>
  );
};
