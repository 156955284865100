import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import throttle from 'lodash/throttle';
import identity from 'lodash/identity';
import {ColumnDef} from '@tanstack/react-table';
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';
import download from 'downloadjs';
import {Button, Input} from 'reactstrap';

import {WorkspaceContext} from 'src/context';
import {WorkingOnIt} from 'src/components';
import {RELATIONSHIP_REPORT_STATE_STORAGE_KEY_PREFIX} from 'src/constants';
import {
  getRelationshipReport,
  getRelationshipReportCsv,
  searchApplications,
  searchRelationshipTypes,
} from 'src/api/reports';
import {PageDataType} from 'src/types';
import {Layout, ListingPage, NodeDetailModal, MaterializedViewAlert} from 'src/components';
import {useStoredValue} from 'src/hooks';
import {useQuery} from 'src/hooks/router';

import './reports.css';

type DropDownItem = {
  label: string,
  value: string
}

export const RelationshipReport = () => {
  const {currentWorkspace} = useContext(WorkspaceContext);
  const currentMaterializedViewDefinition = currentWorkspace?.materializedViewDefinition;
  const currentMaterializedViewDefinitionId = currentMaterializedViewDefinition?.id;

  const [items, setItems] = useState([]);
  const [pageData, setPageData] = useState<PageDataType>();
  const {get} = useQuery();
  const applicationQueryParamValue = get('applicationLabel');
  const itemTypeQueryParamValue = get('relationshipType');
  const [relationshipTypes, setRelationshipTypes] =
    useStoredValue<Array<DropDownItem>>(
        `${RELATIONSHIP_REPORT_STATE_STORAGE_KEY_PREFIX}--selected-relationship-types`,
      itemTypeQueryParamValue ? [{label: itemTypeQueryParamValue, value: itemTypeQueryParamValue}] : [],
    );

  const [applications, setApplications] = useStoredValue<Array<DropDownItem>>(`${RELATIONSHIP_REPORT_STATE_STORAGE_KEY_PREFIX}--selected-applications`, []);
  const [nodeDetailId, setNodeDetailId] = useState<string>();
  const [isNodeDetailOpen, setNodeDetailOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // this is a hack to force a reload of items
  const [reload, setReload] = useState<boolean>(false);
  const [hasData, setHasData] = useState(!currentMaterializedViewDefinitionId);
  const [toFilter, _setToFilter] = useStoredValue<string | undefined>(`${RELATIONSHIP_REPORT_STATE_STORAGE_KEY_PREFIX}--text-filter`, undefined);

  const setToFilter = throttle(_setToFilter, 1000);

  useEffect(() => {
    setReload((v) => !v);
    setItems([]);
    setPageData(undefined);
  }, [currentMaterializedViewDefinitionId]);

  const columns = useMemo<ColumnDef<any>[]>(
      () => [
        {
          header: 'From Item\'s Parent',
          id: 'fromParentName',
          accessorFn: identity,
          enableSorting: false,
          cell: ({getValue}) => {
            const {fromParentId, fromParentName} = getValue();
            return (
              <Button color="link" onClick={() => toggleNodeDetail(true, fromParentId)}>{fromParentName}</Button>
            );
          },
        },
        {
          header: 'From Item',
          id: 'fromName',
          accessorFn: identity,
          cell: ({getValue}) => {
            const {fromId, fromName, fromIdentity} = getValue();
            return (
              <Button
                color="link"
                onClick={() => toggleNodeDetail(true, fromId)}
              >
                {fromName || fromIdentity}
              </Button>
            );
          },
        },
        {
          header: 'Relationship',
          accessorKey: 'relationshipType',
          enableSorting: false,
        },
        {
          header: 'To Item',
          id: 'toName',
          accessorFn: identity,
          cell: ({getValue}) => {
            const {toId, toName, toIdentity} = getValue();
            return (
              <Button
                color="link"
                onClick={() => toggleNodeDetail(true, toId)}
              >
                {toName || toIdentity}
              </Button>
            );
          },
        },
        {
          header: 'To Item\'s Parent',
          id: 'toParentName',
          accessorFn: identity,
          enableSorting: false,
          cell: ({getValue}) => {
            const {toParentId, toParentName} = getValue();
            return (
              <Button color="link" onClick={() => toggleNodeDetail(true, toParentId)}>{toParentName}</Button>
            );
          },
        },
      ],
      undefined,
  );

  const fetchRelationshipTypes = async (inputValue: string) =>
    new Promise<any>(async (resolve, reject) => {
      const res = await searchRelationshipTypes(inputValue, currentMaterializedViewDefinitionId);
      if (res.status === 'success') {
        const result = res.data.map((i) => ({value: i, label: i}));
        if (itemTypeQueryParamValue && result) {
          const items = result.filter((i) => itemTypeQueryParamValue === i.value);
          if (items && items.length) {
            setRelationshipTypes([items[0]]);
          } else {
            setRelationshipTypes([]);
          }
        }
        resolve(result);
      } else {
        reject(new Error('failed to retrieve types'));
      }
    });

  const fetchApplications = async (inputValue: string) =>
    new Promise<any>(async (resolve, reject) => {
      const res = await searchApplications(inputValue, currentMaterializedViewDefinitionId || undefined);
      if (res.status === 'success') {
        const result = res.data.map((o) => ({value: o.id, label: o.name}));
        if (applicationQueryParamValue && result) {
          const appSelection = result.find((i) => i.label === applicationQueryParamValue);
          setApplications([appSelection]);
        }
        resolve(result);
      } else {
        reject(new Error('failed to retrieve applications'));
      }
    });

  const fetchReport = useCallback(
      async (page = 0, pageSize = 10, sortBy = 'fromIdentity,asc') => {
        if (!applications || applications.length === 0) {
          setItems([]);
          setPageData(undefined);
          return;
        }
        setIsLoading(true);
        const reportRes = await getRelationshipReport(
            page,
            pageSize,
            sortBy,
            relationshipTypes ? relationshipTypes?.map((t) => t.value) : [],
            applications.map((a) => a.value),
            toFilter,
            currentMaterializedViewDefinitionId,
        );
        setIsLoading(false);
        const {data, metaData} = reportRes;
        setItems(data);
        setPageData(metaData ? metaData.pageData : undefined);
      },
      [relationshipTypes, applications, toFilter],
  );

  const exportReport = async () => {
    if (applications && applications.length > 0) {
      setIsLoading(true);
      const res = await getRelationshipReportCsv(
        relationshipTypes ? relationshipTypes.map((t) => t.value) : [],
        applications.map((a) => a.value), currentMaterializedViewDefinitionId);
      if (res) {
        download(
            res,
            'CodeLogic Relationship Report.csv',
            'text/csv;charset=UTF-8',
        );
      } else {
        await Swal.fire('Unable to download CSV', 'Error retrieving CSV from the backend.  Please try again in 30 seconds.', 'error');
      }
      setIsLoading(false);
    }
  };

  const goToNode = (id: string | undefined) => {
    setNodeDetailId(id);
  };

  const toggleNodeDetail = (show: boolean, nodeId?: string) => {
    if (show) {
      setNodeDetailId(nodeId);
    } else {
      setNodeDetailId(undefined);
    }
    setNodeDetailOpen(show);
  };

  return (
    <>
      <MaterializedViewAlert dataAvailable={() => window.location.reload()}/>
      {currentMaterializedViewDefinitionId && !isLoading && !hasData && (
        <WorkingOnIt hadData={()=> setHasData(true)} gotData={() => setHasData(true)}/>
      )}
      {(hasData || !currentMaterializedViewDefinitionId) && <Layout title="Relationship Report" loading={isLoading}>
        <div className="reports-content">
          <div className="filter-cont">
            <AsyncSelect
              key={'type' + reload}
              defaultOptions
              cacheOptions
              isClearable
              isMulti
              className="filter-input"
              placeholder="Relationship Type"
              onChange={(obj) => setRelationshipTypes([...obj])}
              loadOptions={fetchRelationshipTypes}
              value={relationshipTypes}
            />

            <AsyncSelect
              key={'app' + reload}
              defaultOptions
              cacheOptions
              isClearable
              isMulti
              className="filter-input"
              placeholder="Application"
              onChange={(obj) => setApplications([...obj])}
              loadOptions={fetchApplications}
              value={applications}
            />
            <Input
              type='text'
              className='filter-input'
              value={toFilter}
              onChange={(e) => {
                setToFilter(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setToFilter(e.currentTarget.value);
                }
              }}
              placeholder='To Item Filter'
            />
            <Button
              color="primary"
              onClick={exportReport}
              disabled={!applications || applications.length === 0}
            >
              Export
            </Button>
          </div>

          <ListingPage
            columns={columns}
            data={items}
            pageData={pageData}
            fetchData={fetchReport}
          />
        </div>

        {isNodeDetailOpen && (
          <NodeDetailModal
            isOpen={isNodeDetailOpen}
            toggle={() => toggleNodeDetail(false)}
            nodeId={nodeDetailId}
            goToNode={goToNode}
          />
        )}
      </Layout>}
    </>
  );
};
