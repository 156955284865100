import {useEffect, useCallback, useMemo, useState, useRef, ChangeEvent} from 'react';
import moment from 'moment';
import {ColumnDef} from '@tanstack/react-table';
import {
  Input,
  Button,
  InputGroup,
} from 'reactstrap';
import download from 'downloadjs';
import Swal from 'sweetalert2';
import {toInteger} from 'lodash';
import {FaExclamationTriangle} from 'react-icons/fa';
import {Link, useNavigate} from 'react-router-dom';
import ArcProgress from 'react-arc-progress';
import {useSubscription} from 'react-stomp-hooks';

import {ChangeRequest, ChangeRequestConfig, ChangeRequestConfigDefault, ComparisonJob, PageDataType} from 'src/types';
import {searchChangeRequests, getChangeRequestsCsv} from 'src/api/changeRequests';
import {ListingPage} from 'src/components/ListingPage';
import {Layout} from 'src/components/Layout';
import {TextSearch} from 'src/components/TextSearch';
import {ActionsMenu} from 'src/components/ActionsMenu';
import ChangeRequestConfigurationForm from 'src/components/Administration/ChangeRequestTab/Details';
import {CHANGE_REQUEST_CONFIG_PATH, RELATIVE_COMPARISON_VIEW_PATH} from 'src/routes';
import {COLOR_EXTRACTED_85} from 'src/styles/colors';
import {DATE_FORMAT} from 'src/constants';

import {StatCard} from './StatCard';
import {Spinner} from './components';

import './styles.css';

export function getPreviousDate(daysAgo: number): string {
  return moment().subtract(daysAgo, 'days').toISOString();
}

type RequestOption = '1' | '7' | '30' | '90';

export const ChangeRequests = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState <Array<ChangeRequest>>([]);
  const [pageData, setPageData] = useState<PageDataType>();
  const [searchValue] = useState('');
  const [fullSearchValue, setFullSearchValue] = useState('');
  const [requestOption, setRequestOption] = useState<RequestOption>('7');
  const [requestDate, setRequestDate] = useState<string>(getPreviousDate(7));
  const [statusValue, setStatusValue] = useState('');
  const openPRs = items.filter(({state}) => state === 'OPEN').length;
  const closedPRs = items.filter(({state}) => state === 'CLOSED').length;
  const prevStatus = useRef('');
  const prevRequestDate = useRef(requestDate);
  const prevSearchValue = useRef('');
  const searchBoxRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [editData, setEditData] = useState<ChangeRequestConfig>();
  const [defData, setDefData] = useState<ChangeRequestConfigDefault>();

  const [dependsOnReload, setIsReloadRequired] = useState(true);
  const reload = () => setIsReloadRequired(!dependsOnReload);

  const submitSearchRequest = (val: string) => {
    setFullSearchValue(val);
  };

  const handleStatusSelect = (event: ChangeEvent<HTMLInputElement>) => {
    setStatusValue(event.target.value);
  };

  const handleDateSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const selectRange = event.target.value;
    if (selectRange === '7' || selectRange === '30' || selectRange === '90' || selectRange === '1') {
      setRequestOption(selectRange);
      setRequestDate(getPreviousDate(toInteger(selectRange)));
    }
  };

  const handleExport = async () => {
    try {
      setIsLoading(true);

      const res = await getChangeRequestsCsv(searchValue, statusValue, requestDate);
      if (res) {
        download(
            res,
            'ChangeRequestReport.csv',
            'text/csv;charset=UTF-8',
        );
      } else {
        await Swal.fire('Unable to download CSV', 'Error retrieving CSV from the backend.  Please try again in 30 seconds.', 'error');
      }
      setIsLoading(false);
    } catch (error) {
      await Swal.fire('Unable to download CSV', 'Error retrieving CSV from the backend.  Please try again in 30 seconds.', 'error');
    }
  };

  const computeProgress = (jobs: Array<ComparisonJob>): number => {
    const maxObject = jobs
        .filter((j) => j.status === 'RUNNING')
        .reduce((maxObj, currentObj) => {
          if (currentObj.currentStep > maxObj.currentStep) {
            return currentObj;
          }
          return maxObj;
        });
    return maxObject.currentStep / maxObject.totalSteps;
  };

  const columns = useMemo<ColumnDef<ChangeRequest>[]>(
      () => [
        {
          header: 'Request',
          accessorKey: 'title',
          cell: ({row}) => {
            const data = row.original;
            const completeComparisonJob: ComparisonJob | undefined =
              data?.comparisonJobs?.find((j) => j.status === 'COMPLETE');
            return <>
              {completeComparisonJob && data && data.title &&
                <Link to={RELATIVE_COMPARISON_VIEW_PATH.replace(/:entityId/, completeComparisonJob.id)}>{data.title}</Link>}
              {!completeComparisonJob && data && data.title &&
                <span>{data.title}</span>}
            </>;
          },
        },
        {
          header: 'Requestor',
          accessorKey: 'userLogin',
        },
        {
          header: 'Date Opened',
          accessorKey: 'created',
          accessorFn: (d) => {
            if (d.created) {
              return moment(d.created)
                  .local()
                  .format(DATE_FORMAT);
            } else {
              return 'unknown';
            }
          },
        },
        {
          header: 'Date Closed',
          accessorKey: 'closed',
          accessorFn: (d) => {
            if (d.closed) {
              return moment(d.closed)
                  .local()
                  .format(DATE_FORMAT);
            } else {
              return 'Open';
            }
          },
        },
        {
          id: 'actions',
          header: () => <span>Actions</span>,
          cell: ({row}) => {
            const changeRequest = row.original;
            const configs = changeRequest?.changeRequestConfigs;
            const jobs = changeRequest?.comparisonJobs;
            const addConfig = () => {
              setIsOpen(true);
              if (changeRequest) {
                setDefData(
                    {
                      repositoryUrl: changeRequest.baseRepositoryHtmlUrl,
                      branchPattern: changeRequest.headRef,
                    },
                );
              }
            };
            const viewPR = () => {
              window.open(changeRequest?.htmlUrl, '_blank');
            };
            const editConfig = () => {
              if (configs && configs.length === 1) {
                setIsOpen(true);
                setEditData(configs[0]);
              } else {
                navigate(CHANGE_REQUEST_CONFIG_PATH);
              }
            };

            const hasconfigs = configs && configs?.length > 0;
            return (
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <ActionsMenu
                  options={[{
                    onClick: viewPR,
                    title: 'View the request',
                    content: 'View Request',
                  }, {
                    onClick: addConfig,
                    title: 'Add a Change Request configuration',
                    content: 'Add',
                    disabled: hasconfigs,
                  }, {
                    onClick: editConfig,
                    title: configs && configs.length > 1 ? 'View Change Request configs' : 'Edit Change Request config',
                    content: configs && configs.length > 1 ? 'View Configs' : 'Edit',
                    disabled: !hasconfigs,
                  }]}
                />
                {
                  ((!configs ||
                    configs?.length === 0)) && (
                    <button
                      onClick={addConfig}
                      title='Repository has not been configured'
                      style={{background: 'none', border: 'none'}}
                    >
                      <FaExclamationTriangle />
                    </button>
                  )
                }
                {
                  (jobs &&
                    jobs.find((j) => j.status === 'CREATED')) && (
                    <Spinner />
                  )
                }
                {
                  jobs && jobs.find((j) => j.status === 'RUNNING') && (
                    <ArcProgress
                      progress={computeProgress(jobs)}
                      size={50}
                      fillColor={COLOR_EXTRACTED_85}
                      animation={false}
                      thickness={5}
                    >
                    </ArcProgress>
                  )
                }
              </div>
            );
          },
        },
      ],
      undefined,
  );

  useSubscription('/topic/comparisonJobs', async (message) => {
    if (items && items.length > 0) {
      const response: ComparisonJob = JSON.parse(message.body);
      const index =
        items.findIndex((r) => r.comparisonJobs &&
          r.comparisonJobs.filter((j) => j.id === response.id).length > 0);
      if (index >= 0) {
        const newItemArray = items.slice();
        const jobsArray = newItemArray[index].comparisonJobs?.slice();
        const jobIndex = newItemArray[index].comparisonJobs?.findIndex((j) => j.id === response.id);
        if (jobsArray && jobIndex) {
          jobsArray[jobIndex] = response;
          newItemArray[index].comparisonJobs = jobsArray;
          setItems(newItemArray);
        }
      }
    }
  });

  const loadData = useCallback(async (page = 0, pageSize = 10, sort = 'created,desc') => {
    const currentPage = prevStatus.current !== statusValue || prevRequestDate.current !== requestDate || prevSearchValue.current !== fullSearchValue ? 0 : page;
    setIsLoading(true);
    try {
      const results = await searchChangeRequests(fullSearchValue, statusValue, requestDate, currentPage, pageSize, sort);
      const {data, metaData, status} = results;
      if (status === 'success') {
        setItems(data || []);
        setPageData(metaData ? metaData.pageData : undefined);
      } else {
        setItems([]);
        setPageData(undefined);
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    }
    setIsLoading(false);
    prevStatus.current = statusValue;
    prevRequestDate.current = requestDate;
    prevSearchValue.current = fullSearchValue;
  }, [fullSearchValue, statusValue, requestDate, dependsOnReload]);


  useEffect(() => {
    const reset = (e: any) => {
      if ( e.currentTarget?.value === '') {
        setFullSearchValue('');
      }
    };
    searchBoxRef.current?.addEventListener('search', reset);
    return () => {
      searchBoxRef.current?.removeEventListener('search', reset);
    };
  }, [searchBoxRef]);


  return <Layout loading={isLoading}>
    <div className='ChangeRequests__wrapper'>
      <div className='ChangeRequests__tools'>
        <div className='ChangeRequests__requestStats'>
          <StatCard count={openPRs} label="Open" />
          <StatCard count={closedPRs} label="Closed" />
        </div>
        <div className='ChangeRequests__tools-wrapper'>
          <div style={{textAlign: 'right'}}>
            <Button color='secondary' onClick={() => navigate(CHANGE_REQUEST_CONFIG_PATH)}>View configurations</Button>
          </div>
          <div style={{textAlign: 'right'}}>
            <Button color='primary' onClick={handleExport}>Export</Button>
          </div>
          <div className='ChangeRequests__searchTools'>
            <div className='ChangeRequests__searchStatus'>
              <label htmlFor='status' className='ChangeRequests__select-label'>Status</label>
              <Input type='select' id='status' value={statusValue} className="ChangeRequests__select" aria-label="Status select" onChange={handleStatusSelect}>
                <option value='' className='ChangeRequests__option'>All</option>
                <option value="OPEN" className='ChangeRequests__option'>Open</option>
                <option value="CLOSED" className='ChangeRequests__option'>Closed</option>
              </Input>
            </div>
            <div className='ChangeRequests__searchDate'>
              <label htmlFor='reqDate' className='ChangeRequests__select-label'>Date</label>
              <Input type="select" className="ChangeRequests__select" id='reqDate' value={requestOption} aria-label="Date-picker" onChange={handleDateSelect}>
                <option value='1' className='ChangeRequests__option'>Last 24 Hours</option>
                <option value="7" className='ChangeRequests__option'>Last 7 Days</option>
                <option value="30" className='ChangeRequests__option'>Last 30 Days</option>
                <option value="90" className='ChangeRequests__option'>Last 90 Days</option>
              </Input>
            </div>
            <InputGroup className='search input-group-sm'>
              <TextSearch onSearch={submitSearchRequest}/>
            </InputGroup>
          </div>
        </div>
      </div>

      <ListingPage
        columns={columns}
        data={items}
        pageData={pageData}
        fetchData={loadData}
      />
      <ChangeRequestConfigurationForm
        isOpen={isOpen}
        onClose={
          () => {
            setIsOpen(false);
            reload();
            setEditData(undefined);
          }
        }
        config={editData}
        defaults={defData}
      ></ChangeRequestConfigurationForm>
    </div>
  </Layout>
  ;
};
