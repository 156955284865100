import {useContext, useEffect} from 'react';
import {
  Routes,
  Route,
  Navigate, useNavigate, useLocation,
} from 'react-router-dom';

import {WorkspaceContext} from 'src/context';
import {LOCAL_SETTING_KEY_WELCOME_GUIDE} from 'src/constants';
import {useLocalSetting, useFeatureFlag} from 'src/hooks';
import {
  AgentsRoutes,
  AdministrationRoutes,
  LocalSettings,
  ItemReport,
  Login,
  NodeDetailPage,
  RelationshipReport,
  WorkspacePage,
  WorkspacesPage,
  WorkspaceMenuReceiver,
  InvestigateUsagesPage,
  ReleaseAssistantPage,
  ReleaseAssistantCreatePage,
  Search,
  Store,
  ComparisonPage,
} from 'src/components';
import {
  ADMINISTRATION_PATH,
  AGENTS_PATH,
  APP_ROOT,
  AUTH_CALLBACK_PATH,
  AZURE_AD_AUTH_CALLBACK_PATH,
  CHANGE_REQUEST_PATH,
  GETTING_STARTED_PATH,
  INVESTIGATE_USAGES_PATH,
  LOCAL_SETTINGS_PATH,
  NAMESPACES_PATH,
  NODE_ID_PATH,
  RELATIVE_COMPARISON_VIEW_PATH,
  RELEASE_ASSISTANT_CREATE_PATH,
  RELEASE_ASSISTANT_PATH,
  REPORTS_ITEM_PATH,
  REPORTS_RELATIONSHIPS_PATH,
  SCAN_ARTIFACTS_PATH,
  SCAN_DATABASE_PATH,
  SEARCH_PATH,
  STORE_PATH,
  WELCOME_WIZARD_PATH,
  WORKSPACE_COPY_PATH,
  WORKSPACE_CREATE_PATH,
  WORKSPACE_EDIT_PATH,
  WORKSPACES_PATH,
  WORKSPACE_VIEW_PATH,
} from 'src/routes';
import {AzureAdCallback} from 'src/components/Login/AzureAd';
import {
  ChangeRequests,
  GettingStartedGuide,
  NamespacesPage,
  ScanArtifactsPage,
  ScanDatabasePage,
  SideNav,
  WelcomeWizard,
} from 'src/components';
import {useQuery} from 'src/hooks/router';
import {getAgents} from 'src/api/agents';

export const AuthenticatedRoutes = () => {
  const {get} = useQuery();
  const [serverSideScanningEnabled] = useFeatureFlag('FEATURE_FLAG_SERVER_SIDE_SCANNING');
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const workspaceIdQueryParamValue = get('workspaceId');
  const {currentWorkspace, initializeWorkspace} = useContext(WorkspaceContext);
  const [welcomeWizardEnabled] = useLocalSetting(LOCAL_SETTING_KEY_WELCOME_GUIDE, true);

  const initializeStoreWorkspace = async () => {
    await initializeWorkspace(workspaceIdQueryParamValue || undefined);

    if (!currentWorkspace) {
      const timer = setTimeout(() => {
        if (!currentWorkspace) {
          initializeWorkspace(workspaceIdQueryParamValue || undefined);
        }
      });
      return () => clearTimeout(timer);
    }
  };

  useEffect(() => {
    if (workspaceIdQueryParamValue) {
      navigate(pathname, {replace: true});
    }
    initializeStoreWorkspace();
  }, []);

  useEffect(() => {
    if (welcomeWizardEnabled) {
      const path = serverSideScanningEnabled ? WELCOME_WIZARD_PATH : GETTING_STARTED_PATH;
      (async () => {
        const response = await getAgents({page: 0, pageSize: 1});
        if (response.status === 'success') {
          if (!response.metaData?.pageData?.totalResults || response.metaData.pageData.totalResults <= 0) {
            navigate(path);
          }
        }
      })();
    }
  }, [welcomeWizardEnabled, serverSideScanningEnabled]);


  return (
    <>
      <SideNav />
      <div className="AppContent">
        <WorkspaceMenuReceiver />
        <div className="PageContent">
          <Routes>
            <Route
              path={APP_ROOT}
              element={<Navigate to={SEARCH_PATH} replace />}
            />
            <Route
              path={GETTING_STARTED_PATH}
              element={<GettingStartedGuide />} />
            <Route
              path={`${WELCOME_WIZARD_PATH}/*`}
              element={<WelcomeWizard />} />
            <Route
              path={NAMESPACES_PATH}
              element={<NamespacesPage />} />
            <Route
              path={SCAN_ARTIFACTS_PATH}
              element={<ScanArtifactsPage />} />
            <Route
              path={SCAN_DATABASE_PATH}
              element={<ScanDatabasePage />} />
            <Route
              path={SEARCH_PATH}
              element={<Search />} />
            <Route
              path={INVESTIGATE_USAGES_PATH}
              element={<InvestigateUsagesPage />} />
            <Route
              path={CHANGE_REQUEST_PATH}>
              <Route
                path={CHANGE_REQUEST_PATH}
                element={<ChangeRequests />}
              />
              <Route
                path={RELATIVE_COMPARISON_VIEW_PATH}
                element={<ComparisonPage />}
              />
            </Route>
            <Route
              path={REPORTS_RELATIONSHIPS_PATH}
              element={<RelationshipReport />} />
            <Route
              path={REPORTS_ITEM_PATH}
              element={<ItemReport />} />
            <Route
              path={NODE_ID_PATH}
              element={<NodeDetailPage />}
            />
            <Route
              path={WORKSPACES_PATH}
              element={<WorkspacesPage />}
            />
            <Route
              path={WORKSPACE_CREATE_PATH}
              element={<WorkspacePage />}
            />
            <Route
              path={WORKSPACE_VIEW_PATH}
              element={<WorkspacePage />}
            />
            <Route
              path={WORKSPACE_EDIT_PATH}
              element={<WorkspacePage />}
            />
            <Route
              path={WORKSPACE_COPY_PATH}
              element={<WorkspacePage />}
            />
            <Route
              path={STORE_PATH}
              element={<Store />}
            />
            <Route path={RELEASE_ASSISTANT_PATH}>
              <Route
                path={RELEASE_ASSISTANT_PATH}
                element={<ReleaseAssistantPage />}
              />
              <Route
                path={RELATIVE_COMPARISON_VIEW_PATH}
                element={<ComparisonPage />}
              />
            </Route>
            <Route
              path={RELEASE_ASSISTANT_CREATE_PATH}
              element={<ReleaseAssistantCreatePage />}
            />
            <Route path={AUTH_CALLBACK_PATH} element={<Login />} />
            <Route path={AZURE_AD_AUTH_CALLBACK_PATH} element={<AzureAdCallback />} />
            <Route path={`${ADMINISTRATION_PATH}/*`} element={<AdministrationRoutes />} />
            <Route path={`${AGENTS_PATH}/*`} element={<AgentsRoutes />} />
            <Route path={`${LOCAL_SETTINGS_PATH}/*`} element={<LocalSettings />} />
          </Routes>
        </div>
      </div>
    </>
  );
};
