import {useContext, useEffect, useState} from 'react';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import {IoMdApps} from 'react-icons/io';

import {
  LoadingPlaceholder,
  AsyncSelect,
} from 'src/components';
import {useFeatureFlag, useWorkspaces} from 'src/hooks';
import {WorkspaceContext} from 'src/context';
import {MaterializedViewDefinition} from 'src/types';

import {Panel} from '../Panel';

import {makeDefinitionLoader, makeMaterializedViewLoader} from './utils';

import './styles.css';

const NO_SELECTED_WORKSPACE_DISPLAY_TEXT = '[No Workspace Selected]';

export const WorkspaceSelector = ({isExpanded = true}: {isExpanded?: boolean}) => {
  const [showWorkspaces, setShowWorkspaces] = useState(false);
  const {
    currentWorkspace,
    currentMaterializedViewDefinition,
    setCurrentMaterializedViewDefinition,
    currentMaterializedView,
    setCurrentMaterializedView,
    loading,
  } = useContext(WorkspaceContext);
  const [enabled] = useFeatureFlag('FEATURE_FLAG_SELECT_MATERIALIZED_VIEWS');


  const {loading: loadingWorkspaces, workspaces, loadWorkspaces} = useWorkspaces({autoInitialize: true});


  const currentWorkspaceText = currentWorkspace ? currentWorkspace.displayName : NO_SELECTED_WORKSPACE_DISPLAY_TEXT;

  useEffect(() => {
    loadWorkspaces();
  }, [enabled]);

  return (
    <>
      <Dropdown className='WorkspaceSelector' isOpen={showWorkspaces} toggle={() => {
        showWorkspaces && loadWorkspaces();
        setShowWorkspaces((v) => !v);
      }}>
        <DropdownToggle className='WorkspaceToggle' color='link' >
          <IoMdApps className='WorkspaceToggleIcon' fontSize='1em' title={currentWorkspaceText}/>
          {isExpanded &&
            <>
              {' '}
              <span className='WorkspaceSelected' title={currentWorkspaceText}>
                {loading ? (
                <LoadingPlaceholder plaintext />
                ) : (
                  currentWorkspaceText
                )}
              </span>
            </>
          }
        </DropdownToggle>
        {workspaces && (
          <DropdownMenu className='WorkspaceMenu'>
            <Panel
              workspaces={workspaces}
              loading={loadingWorkspaces}
              onPerformAction={() => setShowWorkspaces(false)}
            />
          </DropdownMenu>
        )}
      </Dropdown>
      {enabled && (
        <>
          {currentWorkspace && (
            <div style={{width: '100%'}}>
              <AsyncSelect<MaterializedViewDefinition>
                key={`${currentWorkspace.id}--${currentMaterializedViewDefinition?.id}`}
                value={currentMaterializedViewDefinition}
                placeholder='Choose Materialized View Definition'
                onChange={(v) => setCurrentMaterializedViewDefinition(v || undefined)}
                getOptionLabel={({displayName}) => displayName || 'unnamed'}
                getOptionValue={({id}) => id}
                loadOptions={makeDefinitionLoader(currentWorkspace.materializedViewDefinitions)}
              />
            </div>
          )}
          {currentMaterializedViewDefinition && (
            <div style={{width: '100%'}}>
              <AsyncSelect<{id?: string}>
                key={`${currentMaterializedViewDefinition.id}--${currentMaterializedView}`}
                value={{id: currentMaterializedView}}
                placeholder='Choose Materialized View'
                onChange={(x) => x && setCurrentMaterializedView(x.id)}
                getOptionLabel={({id}) => id || 'unnamed'}
                getOptionValue={({id}) => id || 'unnamed'}
                loadOptions={makeMaterializedViewLoader(currentMaterializedViewDefinition.id)}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
