import {useCallback, useContext, useEffect} from 'react';

import {SelectedIdsContextProvider, SelectedIdsContext, WorkspaceContext} from 'src/context';
import {isDefined} from 'src/types/util';
import {
  DependencyGraph,
  DelayedDisplay,
  LoadingPlaceholder,
  NodeUsageTable,
  ItemReport,
  RelationshipReport,
  GridColumnsLayout,
  TabList,
  NodeDetailPage,
  Report,
} from 'src/components';
import {useFeatureFlag, useResource} from 'src/hooks';
import {makeResourceFetch} from 'src/api';
import {UsagesTab} from 'src/components/NodeDetail/UsagesTab';

import './styles.css';

const max = Math.max;

const isRelationship = (id: string) => id.includes('.');
const isNotRelationship = (id: string) => !isRelationship(id);

export const Search = () => (
  <SelectedIdsContextProvider>
    <SearchTabs />
  </SelectedIdsContextProvider>
);

const SearchTabs = () => {
  const {selectedIds} = useContext(SelectedIdsContext);
  const {
    currentWorkspace,
    currentMaterializedViewDefinition,
    currentMaterializedView,
    fetchLatestMaterializedView,
  } = useContext(WorkspaceContext);

  const fetchPendingBuildStatus = useCallback(makeResourceFetch<boolean>({
    path: `materialized-view-definition/${currentMaterializedViewDefinition?.id}/pendingBuild`,
  }), [currentMaterializedViewDefinition]);

  const {
    loadEntity: checkIsBuildPending,
    entity: isBuildPending,
  } = useResource(fetchPendingBuildStatus);

  const loaded = currentWorkspace && currentMaterializedViewDefinition && currentMaterializedView;

  useEffect(() => {
    if (!loaded && currentMaterializedViewDefinition?.id) {
      checkIsBuildPending({});
      const timer = setInterval(() => {
        checkIsBuildPending({});
        if (isBuildPending === false) {
          clearInterval(timer);
          fetchLatestMaterializedView(currentMaterializedViewDefinition.id);
        }
      }, 5000);

      return () => clearInterval(timer);
    }
  }, [loaded, currentMaterializedViewDefinition?.id, checkIsBuildPending]);

  const [advancedReportingEnabled] = useFeatureFlag('FEATURE_FLAG_ADVANCED_REPORTING');
  const [deprecatedReportingEnabled] = useFeatureFlag('FEATURE_FLAG_DEPRECATED_REPORTING');
  const renderableNodes = selectedIds.filter(isNotRelationship);
  const detailNodes = selectedIds.filter(isNotRelationship);
  const detailLen = detailNodes.length;
  const renderableLen = renderableNodes.length;

  const renderableIds = renderableNodes.slice(max(renderableLen - 5, 0), renderableLen);
  const detailIds = detailNodes.slice(max(detailLen - 2, 0), detailLen);

  return (
    <TabList tabs={[{
      tab: 'Search',
      pane: (
        <div className="Search__container">
          {loaded ? (
              <DependencyGraph
                currentWorkspaceId={currentWorkspace.id}
                currentMaterializedViewDefinitionId={currentMaterializedViewDefinition.id}
                currentMaterializedViewId={currentMaterializedView}
              />
            ) : (
              <DelayedDisplay style={{flex: 1}}>
                {(isBuildPending || !currentMaterializedView) ? (
                  <h1>Building Graph<LoadingPlaceholder noDelay={true} plaintext={true} /></h1>
                ) : (
                  <h1>No data</h1>
                )}
              </DelayedDisplay>

            )}
        </div>
      ),
    },
      deprecatedReportingEnabled ? {
        tab: 'Item Report',
        pane: (
          <ItemReport />
        ),
      } : undefined,
      deprecatedReportingEnabled ? {
        tab: 'Relationship Report',
        pane: (
          <RelationshipReport />
        ),
      } : undefined,
      advancedReportingEnabled ? {
        tab: 'Report',
        pane: (
          <Report />
        ),
      } : undefined,
      ...renderableIds.length > 0 ? [{
        group: '2',
        tab: 'Relationships',
        pane: (
          <div className="Search__container Search__container--with-grid-dividers">
            <GridColumnsLayout columnRatios={renderableIds.map(() => 1)}>
              {renderableIds.map((id) => (
                <div key={id} style={{padding: '1rem'}}>
                  <NodeUsageTable id={id} />
                </div>
              ))}
            </GridColumnsLayout>
          </div>
        ),
      }, {
        group: '2',
        tab: 'Impact Analysis',
        pane: (
          <div className="Search__container Search__container--with-grid-dividers">
            <GridColumnsLayout columnRatios={detailIds.map(() => 1)}>
              {detailIds.map((id) => (
                <UsagesTab key={id} id={id}/>
              ))}
            </GridColumnsLayout>
          </div>
        ),
      }, {
        group: '2',
        tab: 'Item Details',
        pane: (
          <div className="Search__container Search__container--with-grid-dividers">
            <GridColumnsLayout columnRatios={renderableIds.map(() => 1)}>
              {renderableIds.map((id) => (
                <NodeDetailPage key={id} id={id} />
              ))}
            </GridColumnsLayout>
          </div>
        ),
      }] : []].filter(isDefined)} />
  );
};
