import {useCallback, useContext, useEffect, useState, useMemo} from 'react';
import download from 'downloadjs';
import {Button} from 'reactstrap';
import keyBy from 'lodash/keyBy';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import debounce from 'lodash/debounce';
import {FaTimes} from 'react-icons/fa';

import {CURRENT_QUERY} from 'src/constants';
import {WorkspaceContext} from 'src/context';
import {makeResourceListFetch, makeResourceFetch} from 'src/api';
import {useStoredValue, useResource} from 'src/hooks';
import {
  ConfirmCancelInput,
  ConfirmCancelButton,
  ErrorPlaceholder,
  FloatingControls,
  GridColumnsLayout,
  MultiSelect,
  MultiSelectList,
  Section,
  Select,
  Table,
  ToggledSetting,
} from 'src/components';
import {
  CreateAdvancedReport,
  AdvancedReport,
  AdvancedQuery,
  GroupNodeSearchResult,
  MaterializedViewDefinition,
} from 'src/types';

import './styles.css';

const ADVANCED_QUERY_SPECPIFIC_FIELDS = ['queryParts', 'returns'];

const FIELD_NAME_LABEL_MAP = {
  'id': 'id',
  'identity': 'identity',
  'name': 'name',
  'primaryLabel': 'primary label',
  'rootName': 'root name',
  'rootSHA1': 'root SHA',
  'directLink': 'direct link',
} as const;

const FIELDS = Object.keys(FIELD_NAME_LABEL_MAP);

const NEW_ID_VALUE = 'new';

const NEW_QUERY: AdvancedReport = {
  id: 'new',
  name: '[New Report]',
  description: '',
  createdBy: '',
  createdOn: '',
  queryParts: [{
    applications: [],
    queryType: 'NODE',
    labels: [],
    fuzzyLabels: [],
    caseSensitive: false,
    deepRelationship: false,
    matchType: 'CONTAINS',
    properties: {},
  }, {
    applications: [],
    queryType: 'RELATIONSHIP',
    labels: [],
    directionType: 'OUT',
    fuzzyLabels: [],
    caseSensitive: false,
    deepRelationship: false,
    matchType: 'CONTAINS',
    properties: {},
  }, {
    applications: [],
    queryType: 'NODE',
    labels: [],
    fuzzyLabels: [],
    caseSensitive: false,
    deepRelationship: false,
    matchType: 'CONTAINS',
    properties: {},

  }],
  returns: {
    'n_0': [],
    'n_2': [],
  },
};

export const Report = () => {
  const {currentMaterializedViewDefinition, currentMaterializedView} = useContext(WorkspaceContext);

  if (!currentMaterializedViewDefinition || !currentMaterializedView) {
    return (
      <ErrorPlaceholder message="missing Materialized View Definition" />
    );
  }

  return (
    <ReportContent
      currentMaterializedViewDefinition={currentMaterializedViewDefinition}
      currentMaterializedViewId={currentMaterializedView}
    />
  );
};

const ReportContent = ({
  currentMaterializedViewDefinition,
  currentMaterializedViewId,
}: {
  currentMaterializedViewDefinition: MaterializedViewDefinition,
  currentMaterializedViewId: string,
}) => {
  const [{page, pageSize}, setQueryPagination] = useState<{page: number, pageSize: number}>({
    page: 0,
    pageSize: 10,
  });
  const [newQueryName, setNewQueryName] = useState<string | undefined>();
  const [selectedReport, setSelectedReport] = useStoredValue<AdvancedReport | undefined>(CURRENT_QUERY, undefined);
  const [selectedSourceApplications, setSelectedSourceApplications] = useState<Array<string>>([]);
  const [selectedSourceNodeLabels, setSelectedSourceNodeLabels] = useState<Array<string>>([]);
  const [returnedSourceNodeFields, setReturnedSourceNodeFields] = useState<Array<string>>([]);

  const [selectedConnectingRelationships, setSelectedConnectingRelationships] = useState<Array<string>>([]);
  const [deepRelationshipSearch, setDeepRelationshipSearch] = useState<boolean>(!!NEW_QUERY.queryParts[1].deepRelationship);

  const [selectedTargetApplications, setSelectedTargetApplications] = useState<Array<string>>([]);
  const [selectedTargetNodeLabels, setSelectedTargetNodeLabels] = useState<Array<string>>([]);
  const [returnedTargetNodeFields, setReturnedTargetNodeFields] = useState<Array<string>>([]);

  const [columnFilters, setColumnFilters] = useState<{[key: string]: string | undefined}>({});

  const {
    n_0: n0Filters,
    r_1: r1Filters,
    n_2: n2Filters,
  } = Object.entries(columnFilters).reduce((accum, [k, v]) => {
    const [node, property] = k.split('.');
    if (!accum[node]) {
      accum[node] = {};
    }

    if (!accum[node][property]) {
      accum[node][property] = v;
    }

    return accum;
  }, {
    // eslint-disable-next-line camelcase
    n_0: {} as Record<string, string>,

    // eslint-disable-next-line camelcase
    r_1: {} as Record<string, string>,

    // eslint-disable-next-line camelcase
    n_2: {} as Record<string, string>,
  } as const);

  const currentQuery = useMemo<AdvancedQuery>(() => {
    const query = {
      queryParts: [{
        applications: selectedSourceApplications,
        queryType: 'NODE' as const,
        fuzzyLabels: selectedSourceNodeLabels.map((l) => [l]),
        caseSensitive: false,
        deepRelationship: false,
        matchType: 'CONTAINS' as const,
        properties: Object.entries(n0Filters).reduce((accum, [k, v]) => {
          accum[k] = v;
          return accum;
        }, {} as Record<string, string>),
      }, {
        applications: [],
        queryType: 'RELATIONSHIP' as const,
        labels: !deepRelationshipSearch ? selectedConnectingRelationships : undefined,
        directionType: 'OUT' as const,
        fuzzyLabels: deepRelationshipSearch ? [selectedConnectingRelationships] : undefined,
        caseSensitive: false,
        deepRelationship: deepRelationshipSearch,
        properties: Object.entries(r1Filters).reduce((accum, [k, v]) => {
          accum[k] = v;
          return accum;
        }, {} as Record<string, string>),
      }, {
        applications: selectedTargetApplications,
        queryType: 'NODE' as const,
        fuzzyLabels: selectedTargetNodeLabels.map((l) => [l]),
        caseSensitive: false,
        deepRelationship: false,
        matchType: 'CONTAINS' as const,
        properties: Object.entries(n2Filters).reduce((accum, [k, v]) => {
          accum[k] = v;
          return accum;
        }, {} as Record<string, string>),
      }],
      returns: {
        'n_0': returnedSourceNodeFields,
        'n_2': returnedTargetNodeFields,
      },
    };
    if (query.queryParts[1].labels === undefined) {
      delete query.queryParts[1].labels;
    }
    if (query.queryParts[1].fuzzyLabels === undefined) {
      delete query.queryParts[1].fuzzyLabels;
    }

    return query;
  }, [
    selectedSourceApplications,
    selectedSourceNodeLabels,
    n0Filters,
    selectedConnectingRelationships,
    r1Filters,
    selectedTargetApplications,
    selectedTargetNodeLabels,
    n2Filters,
    returnedSourceNodeFields,
    returnedTargetNodeFields,
  ]);

  const hasChanges = !isEqual(currentQuery, pick(selectedReport, ADVANCED_QUERY_SPECPIFIC_FIELDS));

  const updateReport = useCallback(
      makeResourceFetch<AdvancedReport, CreateAdvancedReport>({
        path: `advanced-report/`,
        method: 'PUT',
      }), []);

  const createReport = useCallback(
      makeResourceFetch<AdvancedReport, CreateAdvancedReport>({
        path: 'advanced-report/',
        method: 'POST',
      }), []);

  const fetchReports = useCallback(
      makeResourceListFetch<Array<AdvancedReport>, Record<never, never>, {
        definitionId: string,
        searchTerm: string,
      }>({
        path: 'advanced-report/',
        constantParams: {
          definitionId: currentMaterializedViewDefinition.id,
          searchTerm: '',
        },
      }), [currentMaterializedViewDefinition]);

  const fetchRelationships = useCallback(
      makeResourceListFetch<Array<string>, Record<never, never>, {
        definitionId: string,
        searchTerm: string,
      }>({
        path: 'relationships/types',
        constantParams: {
          definitionId: currentMaterializedViewDefinition.id,
          searchTerm: '',
        },
      }), [currentMaterializedViewDefinition]);

  const fetchNodeLabels = useCallback(
      makeResourceListFetch<Array<string>, Record<never, never>, {
        definitionId: string,
        searchTerm: string,
        paged: boolean,
      }>({
        path: 'nodes/types',
        constantParams: {
          definitionId: currentMaterializedViewDefinition.id,
          searchTerm: '',
          paged: false,
        },
      }), [currentMaterializedViewDefinition]);

  const fetchApplications = useCallback(
      makeResourceListFetch<Array<GroupNodeSearchResult>, Record<never, never>, {
        definitionId: string,
        searchTerm: string,
        groupingTypes: string,
        paged: boolean
      }>({
        path: 'search/groups',
        constantParams: {
          definitionId: currentMaterializedViewDefinition.id,
          searchTerm: '',
          groupingTypes: 'APPLICATION',
          paged: false,
        },
      }), [currentMaterializedViewDefinition]);

  const fetchQueryData = useCallback(
      makeResourceListFetch<Array<{id: string, [key: string]: unknown}>, AdvancedQuery>({
        path: `advanced-report/prototype/materialized-view/${currentMaterializedViewId}`,
        method: 'POST',
      }), [currentMaterializedViewId]);

  const exportQueryData = useCallback(
      makeResourceFetch<Blob, AdvancedQuery>({
        path: `advanced-report/prototype/materialized-view/${currentMaterializedViewId}/csv`,
        method: 'POST',
      }), [currentMaterializedViewId]);


  const {
    entity: applications,
    loadEntity: loadApplications,
    // loading: loadingApplications,
  } = useResource(fetchApplications);

  const applicationByName = keyBy(applications, 'name');

  const {
    entity: relationships,
    loadEntity: loadRelationships,
    // loading: loadingRelationships,
  } = useResource(fetchRelationships);

  const {
    entity: nodeLabels,
    loadEntity: loadNodeLabels,
    // loading: loadingNodeLabels,
  } = useResource(fetchNodeLabels);

  const {
    entity: reports,
    loadEntity: loadReports,
    // loading: loadingReports,
  } = useResource(fetchReports);

  const {
    entity: queryData,
    loadEntity: loadQueryData,
    metadata: queryMetadata,
    loading: loadingQueryData,
  } = useResource(fetchQueryData);

  const debouncedLoadQueryData = useCallback(debounce(loadQueryData, 1000), [loadQueryData]);

  useEffect(() => {
    (async () => {
      loadApplications();
      loadRelationships();
      loadNodeLabels();
      loadReports({pageSize: 10000});
    })();
  }, [
    currentMaterializedViewDefinition,
    currentMaterializedViewId,
  ]);

  useEffect(() => {
    if (!selectedReport) {
      return;
    }

    const queryPart0 = selectedReport.queryParts[0];
    const queryPart1 = selectedReport.queryParts[1];
    const queryPart2 = selectedReport.queryParts[2];

    const filters: {[key: string]: string} = {};

    if (queryPart0) {
      setSelectedSourceApplications(queryPart0.applications || []);
      setSelectedSourceNodeLabels(queryPart0.fuzzyLabels?.flat() || []);
      queryPart0.properties && Object.entries(queryPart0.properties).forEach(([k, v]) => {
        if (typeof v === 'string') {
          filters[`n_0.${k}`] = v;
        }
      });
    }

    if (queryPart1) {
      setSelectedConnectingRelationships((queryPart1.deepRelationship ? (queryPart1.fuzzyLabels || []).flat() : queryPart1.labels) || []);
      setDeepRelationshipSearch(queryPart1.deepRelationship || false);
      queryPart1.properties && Object.entries(queryPart1.properties).forEach(([k, v]) => {
        if (typeof v === 'string') {
          filters[`r_1.${k}`] = v;
        }
      });
    }

    if (queryPart2) {
      setSelectedTargetApplications(queryPart2.applications || []);
      setSelectedTargetNodeLabels(queryPart2.fuzzyLabels?.flat() || []);
      queryPart2.properties && Object.entries(queryPart2.properties).forEach(([k, v]) => {
        if (typeof v === 'string') {
          filters[`n_2.${k}`] = v;
        }
      });
    }

    setColumnFilters(filters);

    const {
      n_0: n0,
      n_2: n2,
    } = selectedReport.returns;

    if (n0) {
      setReturnedSourceNodeFields(n0);
    }

    if (n2) {
      setReturnedTargetNodeFields(n2);
    }
  }, [selectedReport]);

  useEffect(() => {
    if (
      selectedConnectingRelationships && selectedConnectingRelationships.length > 0 &&
      // selectedSourceApplications && selectedSourceApplications.length > 0 &&
      selectedSourceNodeLabels && selectedSourceNodeLabels.length > 0 &&
      // selectedTargetApplications && selectedTargetApplications.length > 0 &&
      selectedTargetNodeLabels && selectedTargetNodeLabels &&
      returnedSourceNodeFields.length + returnedTargetNodeFields.length > 0
    ) {
      debouncedLoadQueryData({
        ...currentQuery,
        page,
        pageSize,
      });
    }
  }, [
    page,
    columnFilters,
    pageSize,
    deepRelationshipSearch,
    returnedSourceNodeFields,
    returnedTargetNodeFields,
    selectedConnectingRelationships,
    selectedSourceApplications,
    selectedSourceNodeLabels,
    selectedTargetApplications,
    selectedTargetNodeLabels,
    currentMaterializedViewId,
  ]);

  const hasSelectedSourceNodeLabels = selectedSourceNodeLabels.length > 0;
  const hasReturnedSourceNodeFields = returnedSourceNodeFields.length > 0;
  const hasReturnedTargetNodeFields = returnedTargetNodeFields.length > 0;
  const hasReturnedFields = hasReturnedSourceNodeFields || hasReturnedTargetNodeFields;

  const hasSelectedConnectingRelationships = selectedConnectingRelationships.length > 0;
  const hasSelectedTargetNodeLabels = selectedTargetNodeLabels.length > 0;

  const readyToQuery = hasSelectedSourceNodeLabels && hasReturnedFields && hasSelectedConnectingRelationships && hasSelectedTargetNodeLabels;

  const noRecordMessage: Array<string> = [];
  if (!hasSelectedSourceNodeLabels) {
    noRecordMessage.push('Source "With type(s)"');
  }

  if (!hasReturnedSourceNodeFields) {
    noRecordMessage.push('Source "Select fields"');
  }

  if (!hasSelectedConnectingRelationships) {
    noRecordMessage.push('Relationship "With Type(s)"');
  }

  if (!hasSelectedTargetNodeLabels) {
    noRecordMessage.push('Target "With Type(s)"');
  }


  return (
    <div style={{
      height: '100%',
      display: 'flex',
      flexFlow: 'column',
    }}>
      <Section>
        <div style={{
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'space-between',
          minWidth: '100%',
        }}>
          <span style={{fontSize: '0.8em'}}>Select report</span>
          <div style={{
            display: 'flex',
            flexFlow: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
            <h1
              style={{fontSize: '1.5em'}}
              title="Select Report"
            >
              <Select<AdvancedReport>
                styles={{
                  container: (styles) => ({
                    ...styles,
                    backgroundColor: 'var(--color-background--standard)',
                    minWidth: '20rem',
                  }),
                  control: (styles) => ({
                    ...styles,
                    'backgroundColor': 'var(--color-background--standard)',
                    'border': '1px solid var(--color-border--standard)',
                    '&:hover': {
                      border: '1px solid var(--color-text-link--hover)',
                      color: 'var(--color-text-link--hover)',
                    },
                  }),
                  indicatorsContainer: (styles) => ({
                    ...styles,
                    backgroundColor: 'var(--color-background--standard)',
                    color: 'var(--color-text-link)',
                  }),
                  valueContainer: (styles) => ({
                    ...styles,
                    backgroundColor: 'var(--color-background--standard)',
                  }),
                  singleValue: (styles) => ({
                    ...styles,
                    backgroundColor: 'var(--color-background--standard)',
                    color: 'var(--color-text-link)',
                  }),
                  menu: (styles) => ({
                    ...styles,
                    backgroundColor: 'var(--color-background--standard)',
                  }),
                  menuList: (styles) => ({
                    ...styles,
                    backgroundColor: 'var(--color-background--standard)',
                    border: '1px solid var(--color-border--standard)',
                  }),
                  option: (styles) => ({
                    ...styles,
                    'backgroundColor': 'var(--color-background--standard)',
                    'color': 'var(--color-text-link)',
                    '&:hover': {
                      backgroundColor: 'var(--color-background--inverse)',
                      color: 'var(--color-text--inverse)',
                    },
                  }),
                }}
                getOptionValue={(option) => option.id}
                formatOptionLabel={(option) => (
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                    <div>
                      <span>{option.name}</span>
                    </div>
                    {(option.id !== NEW_ID_VALUE && option.id !== selectedReport?.id) && (
                      <ConfirmCancelButton
                        promptText={<FaTimes title="Delete" />}
                        confirmText="Delete"
                        onConfirm={async () => {
                          const deleteReport = makeResourceFetch<AdvancedReport>({
                            path: `advanced-report/${option.id}`,
                            method: 'DELETE',
                          });

                          const response = await deleteReport({});
                          if (response.status === 'success') {
                            loadReports({pageSize: 10000});
                          }

                          return false;
                        }}
                      />
                    )}
                  </div>
                )}
                value={selectedReport}
                onChange={(value) => {
                  if (value && value.id === NEW_ID_VALUE) {
                    const [part0, part1, part2] = value.queryParts;

                    setSelectedSourceApplications(part0.applications || []);
                    setSelectedSourceNodeLabels(part0.fuzzyLabels?.flat() || []);
                    setReturnedSourceNodeFields(value.returns['n_0']);

                    setSelectedConnectingRelationships((part1.deepRelationship ? part1.fuzzyLabels?.flat() : part1.labels) || []);
                    setDeepRelationshipSearch(part1.deepRelationship || false);

                    setSelectedTargetApplications(part2.applications || []);
                    setSelectedTargetNodeLabels(part2.fuzzyLabels?.flat() || []);
                    setReturnedTargetNodeFields(value.returns['n_2']);

                    setSelectedReport(value);
                  } else if (value) {
                    setSelectedReport(value);
                  }
                }}
                options={[NEW_QUERY, ...(reports || [])]}
              />
            </h1>
            {selectedReport && selectedReport.id !== NEW_ID_VALUE && hasChanges && (
              <Button
                style={{
                  minWidth: '10em',
                  marginLeft: '1em',
                }}
                onClick={async () => {
                  const newReportShape = {
                    ...selectedReport,
                    ...currentQuery,
                  };
                  if (newReportShape.queryParts[1].deepRelationship) {
                    delete newReportShape.queryParts[1].labels;
                  } else {
                    delete newReportShape.queryParts[1].fuzzyLabels;
                  }
                  await updateReport(newReportShape);
                  setSelectedReport(newReportShape);
                }}
              >Save changes</Button>
            )}
            <ConfirmCancelInput
              position="after"
              style={{
                minWidth: '20em',
                marginLeft: '1em',
              }}
              promptText={<>Save&nbsp;as...</>}
              placeholder="Name your query"
              value={newQueryName}
              validationMessage="Error: query name required"
              onChange={setNewQueryName}
              onCancel={() => {
                setNewQueryName(undefined);
                return true;
              }}
              onConfirm={async () => {
                if (newQueryName) {
                  const {data} = await createReport({
                    name: newQueryName,
                    description: '',
                    ...currentQuery,
                  });

                  if (data) {
                    setSelectedReport(data);
                  }
                  loadReports({pageSize: 10000});
                  return true;
                }

                return false;
              }}
            />
          </div>
        </div>
        <div className="ReportPage__controls">
          <div className="ReportPage__control-section" >
            <p>Source</p>
            <MultiSelect<GroupNodeSearchResult>
              placeholder="Within Application(s)"
              getOptionLabel={(option) => option.name || 'unnamed'}
              getOptionValue={(option) => option.name}
              value={selectedSourceApplications.map((name) => applicationByName[name])}
              options={applications || []}
              onChange={(d) => setSelectedSourceApplications((d || []).map(({name}) => name))}
            />
            <MultiSelect<{name: string, id: string}>
              placeholder="With Type(s)"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={selectedSourceNodeLabels.map((r) => ({id: r, name: r}))}
              options={(nodeLabels || []).map((r) => ({id: r, name: r})) || []}
              onChange={(d) => setSelectedSourceNodeLabels((d || []).map(({id}) => id))}
            />
            <FloatingControls label={`Select Fields (${returnedSourceNodeFields.length})`}>
              <MultiSelectList
                maxColumnCount={1}
                selectAllLabel="Select All"
                options={FIELDS.map((x) => ({
                  id: x,
                  label: FIELD_NAME_LABEL_MAP[x],
                  selected: returnedSourceNodeFields.includes(x),
                }))}
                renderOption={({
                  label,
                  description,
                }: {
                    label: string,
                    description?: string,
                  }) => (
                  <div className="">
                    <div className="">
                      {label}
                    </div>
                    <div className="">
                      {description}
                    </div>
                  </div>
                )}
                onSelectionChanged={(options) => {
                  setReturnedSourceNodeFields(options.filter(({selected}) => selected).map(({id}) => id));
                }}
              />
            </FloatingControls>
          </div>
          <div className="ReportPage__control-section" >
            <p>Relationship</p>
            <MultiSelect<{name: string, id: string}>
              placeholder="With Type(s)"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={selectedConnectingRelationships.map((r) => ({id: r, name: r}))}
              options={(relationships || []).map((r) => ({id: r, name: r})) || []}
              onChange={(d) => setSelectedConnectingRelationships((d || []).map(({id}) => id))}
            />
            <ToggledSetting
              id="deepRelationshipSearch"
              padCheckbox={false}
              selected={deepRelationshipSearch}
              onSelect = {() => setDeepRelationshipSearch(!deepRelationshipSearch)}
            ><span style={{marginLeft: '1em'}} title="Include indirectly related nodes (max distance 6)">Deep search</span></ToggledSetting>
          </div>
          <div className="ReportPage__control-section" >
            <p>Target</p>
            <MultiSelect<GroupNodeSearchResult>
              placeholder="Within Application(s)"
              getOptionLabel={(option) => option.name || 'unnamed'}
              getOptionValue={(option) => option.name}
              value={selectedTargetApplications.map((name) => applicationByName[name])}
              options={applications || []}
              onChange={(d) => setSelectedTargetApplications((d || []).map(({name}) => name))}
            />
            <MultiSelect<{name: string, id: string}>
              placeholder="With Type(s)"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={selectedTargetNodeLabels.map((r) => ({id: r, name: r}))}
              options={(nodeLabels || []).map((r) => ({id: r, name: r})) || []}
              onChange={(d) => setSelectedTargetNodeLabels((d || []).map(({id}) => id))}
            />
            <FloatingControls label={`Select Fields (${returnedTargetNodeFields.length})`}>
              <MultiSelectList
                maxColumnCount={1}
                selectAllLabel="Select All"
                options={FIELDS.map((x) => ({
                  id: x,
                  label: FIELD_NAME_LABEL_MAP[x],
                  selected: returnedTargetNodeFields.includes(x),
                }))}
                renderOption={({
                  label,
                  description,
                }: {
                    label: string,
                    description?: string,
                  }) => (
                  <div className="">
                    <div className="">
                      {label}
                    </div>
                    <div className="">
                      {description}
                    </div>
                  </div>
                )}
                onSelectionChanged={(options) => {
                  setReturnedTargetNodeFields(options.filter(({selected}) => selected).map(({id}) => id));
                }}
              />
            </FloatingControls>
          </div>
        </div>
      </Section>
      <div style={{
        display: 'flex',
        position: 'relative',
        flexFlow: 'column',
        flex: '100%',
      }}>
        <GridColumnsLayout columnRatios={[1]}>
          {/* <Section title="Debug">
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <div>
                <p>currentQuery</p>
                <pre>{JSON.stringify(currentQuery, (k,v)=> typeof v === 'undefined' ? '<undefined>' : v, 2)}</pre>
              </div>
              <div>
                <p>selectedReport</p>
                <pre>{JSON.stringify(pick(selectedReport, ADVANCED_QUERY_SPECPIFIC_FIELDS), null, 2)}</pre>
              </div>
              <div>
                <p>Has changes</p>
                <pre>{JSON.stringify(hasChanges)}</pre>
              </div>
            </div>
          </Section> */}
          <Section title={
            <>
              <h2 style={{margin: 0}}>Query results</h2>
              {queryData && (
                <Button
                  style={{
                    minWidth: '10em',
                    marginLeft: '1em',
                  }}
                  onClick={async () => {
                    const data: Blob = (await exportQueryData(currentQuery)) as unknown as Blob;

                    download(data, `${!selectedReport ? 'queryResults' : hasChanges ? `${selectedReport.name} (unsaved changes)` : selectedReport.name}.csv`, 'text/csv');
                  }}
                >Export CSV</Button>
              )}
            </>
          }>
            <Table
              noRecordsMessage={readyToQuery ? 'No results' : (
                <div style={{fontSize: '0.8em'}}>
                  <div>
                    <p>
                      Use the menus above to build and narrow your query.  One you have results returned from
                      a query, you can filter down the results to those with matching strings by returned field.
                    </p>
                    <p>At a minimum, you need to make selections for the following in order to run a query:</p>
                  </div>
                  <div>
                    {noRecordMessage.map((m) => <div key={m}>{m}</div>)}
                  </div>
                </div>
              )}
              className="ReportPage__table"
              loading={loadingQueryData}
              onFilterChange={(d) => {
                setColumnFilters(d);
                if (page !== 0) {
                  setQueryPagination({
                    pageSize,
                    page: 0,
                  });
                }
              }}
              rows={queryData}
              onSelectPageSize={(pageSize) => setQueryPagination({
                pageSize,
                page,
              })}
              onPageChange={(page) => setQueryPagination({
                pageSize,
                page,
              })}
              metadata={queryMetadata}
              columns={[
                ...returnedSourceNodeFields.map((name) => ({
                  id: `n_0.${name}`,
                  header: () => `source ${FIELD_NAME_LABEL_MAP[name]}`,
                  accessorKey: `n_0_${name}`,
                  canFilter: true,
                  filter: n0Filters[name] || '',
                  cell: (value) => value[`n_0_${name}`],
                })),
                ...returnedTargetNodeFields.map((name) => ({
                  id: `n_2.${name}`,
                  accessorKey: `n_2_${name}`,
                  canFilter: true,
                  filter: n2Filters[name] || '',
                  header: () => `target ${FIELD_NAME_LABEL_MAP[name]}`,
                  cell: (value) => value[`n_2_${name}`],
                })),
              ]}
            />
          </Section>
        </GridColumnsLayout>
      </div>
    </div>
  );
};

