export const FEATURE_FLAG_SCAN_SPACE_UI = {
  key: 'feature-flag-scan-space-ui',
  defaultValue: true as boolean,
  devOnly: false,
  name: 'ScanSpaceUI',
  description: 'Show the Administration tab for configuring scan spaces.',
} as const;

export const FEATURE_FLAG_WELCOME_WIZARD = {
  key: 'feature-flag-welcome-wizard',
  defaultValue: true as boolean,
  devOnly: true,
  name: 'WelcomeWizard',
  description: 'Controls whether the welcome wizard shows if no agents are configured.',
} as const;

export const FEATURE_FLAG_SERVER_SIDE_SCANNING = {
  key: 'feature-flag-server-side-scanning',
  defaultValue: false as boolean,
  devOnly: true,
  name: 'WelcomeWizard',
  description: 'Controls whether the welcome wizard shows if no agents are configured.',
} as const;

export const FEATURE_FLAG_SELECT_MATERIALIZED_VIEWS = {
  key: 'feature-flag-select-materialized-views',
  defaultValue: false as boolean,
  devOnly: true,
  name: 'SelectMaterializedViews',
  description: 'Allow the user to explicitly specify materialized view definitions ande materialized views',
} as const;

export const FEATURE_FLAG_LICENSING = {
  key: 'feature-flag-licensing',
  defaultValue: false as boolean,
  devOnly: true,
  name: 'Licensing',
  description: 'Enable licensing features',
} as const;

export const FEATURE_FLAG_ADVANCED_REPORTING = {
  key: 'feature-flag-advanced-reporting',
  defaultValue: false as boolean,
  devOnly: false,
  name: 'Advanced Reporting',
  description: 'Enable advanced reporting',
} as const;

export const FEATURE_FLAG_DEPRECATED_REPORTING = {
  key: 'feature-flag-deprecated-reporting',
  defaultValue: true as boolean,
  devOnly: true,
  name: 'Deprecated Reporting Tools',
  description: 'Enable deprecated reporting tools',
} as const;
