import {MaterializedViewDefinition} from 'src/types';
import {getMaterializedViewId} from 'src/api/materializedViews';

export const makeDefinitionLoader = (options: Array<MaterializedViewDefinition>) => async () => {
  return Promise.resolve(options);
};

export const makeMaterializedViewLoader = (definitionId: string) => async () => {
  const result =
    await getMaterializedViewId(definitionId);

  if (result.status === 'success' && !!result.data) {
    return [{id: result.data}] || [];
  } else {
    return [];
  }
};


