import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import identity from 'lodash/identity';
import {ColumnDef} from '@tanstack/react-table';
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';
import download from 'downloadjs';
import {Button} from 'reactstrap';

import {useStoredValue} from 'src/hooks';
import {useQuery} from 'src/hooks/router';
import {WorkspaceContext} from 'src/context';
import {
  ITEM_REPORT_STATE_STORAGE_KEY_PREFIX,
} from 'src/constants';
import {Layout, ListingPage, MaterializedViewAlert, NodeDetailModal} from 'src/components';
import {getItemReport, getItemReportCsv, searchApplications, searchItemTypes} from 'src/api/reports';
import {PageDataType} from 'src/types';
import {WorkingOnIt} from 'src/components';

export const ItemReport = () => {
  const {currentWorkspace} = useContext(WorkspaceContext);
  const currentMaterializedViewDefinition = currentWorkspace?.materializedViewDefinition;
  const currentMaterializedViewDefinitionId = currentMaterializedViewDefinition?.id;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [items, setItems] = useState([]);
  const [pageData, setPageData] = useState<PageDataType>();
  const {get, getAll} = useQuery();
  const applicationQueryParamValue = get('applicationId');
  const itemTypeQueryParamValues = getAll('typeName');
  const [itemTypes, setItemTypes] = useStoredValue(`${ITEM_REPORT_STATE_STORAGE_KEY_PREFIX}--selected-item-types`, itemTypeQueryParamValues.map((t) => ({label: t, value: t})));

  const [nodeDetailId, setNodeDetailId] = useState<string | null>();
  const [applicationValues, setApplicationValues] = useStoredValue<Array<{value?: string}>>(`${ITEM_REPORT_STATE_STORAGE_KEY_PREFIX}--selected-applications`, []);
  // this is a hack to force a reload of items
  const [reload, setReload] = useState(false);
  const [hasData, setHasData] = useState(!currentMaterializedViewDefinitionId);

  useEffect(() => {
    setReload((v) => !v);
    setItems([]);
    setPageData(undefined);
  }, [currentMaterializedViewDefinitionId]);

  const columns = useMemo<ColumnDef<any>[]>(
      () => [
        {
          header: 'Item Name',
          id: 'name',
          accessorFn: identity,
          cell: ({getValue}) => {
            const {id, name, identity} = getValue();
            return (
              <Button
                className="row-button"
                color="link"
                onClick={() => setNodeDetailId(id)}
              >
                {name || identity}
              </Button>
            );
          },
        },
        {
          header: 'Item Type',
          accessorKey: 'primaryLabel',
        },
        {
          header: 'Root Item',
          accessorKey: 'scanRootName',
        },
        {
          header: 'Inbound(TT)',
          accessorKey: 'totalInboundRelationships',
        },
        {
          header: 'Outbound(TT)',
          accessorKey: 'totalOutboundRelationships',
        },
      ],
      undefined,
  );

  const fetchItemTypes = (inputValue: string) =>
    new Promise<any>(async (resolve, reject) => {
      const res = await searchItemTypes(inputValue, currentMaterializedViewDefinitionId);
      if (res.status === 'success') {
        const result = res.data.map((i) => ({value: i, label: i}));

        if (itemTypeQueryParamValues.length && result) {
          const items = result.filter((i) => itemTypeQueryParamValues.includes(i.value) );
          if (items.length) {
            setItemTypes(items);
          } else {
            setItemTypes([]);
          }
        }
        resolve(result);
      } else {
        reject(new Error('failed to retrieve types'));
      }
    });

  const fetchApplications = async (inputValue: string) =>
    new Promise<any>(async (resolve, reject) => {
      const res = await searchApplications(inputValue, currentMaterializedViewDefinitionId || undefined);
      if (res.status === 'success') {
        const result = res.data.map((o) => ({value: o.id, label: o.name}));
        if (applicationQueryParamValue && result) {
          const item = result.find((i) => i.value === applicationQueryParamValue);
          if (item) {
            setApplicationValues([item]);
          }
        }
        resolve(result);
      } else {
        reject(new Error('failed to retrieve applications'));
      }
    });

  const fetchReport = useCallback(
      async (page = 0, pageSize = 10, sortBy = 'identity,asc') => {
        if (!applicationValues || applicationValues.length <= 0) {
          setItems([]);
          setPageData(undefined);
          return;
        }
        setIsLoading(true);
        const reportRes = await getItemReport(
            page,
            pageSize,
            sortBy,
        itemTypes.length > 0 ? itemTypes.map((t) => t.value) : null,
        applicationValues.filter(Boolean).map((v) => v.value),
        currentMaterializedViewDefinitionId,
        );
        setIsLoading(false);
        const {data, metaData, status} = reportRes;
        if (status === 'success') {
          setItems(data);
          setPageData(metaData ? metaData.pageData : undefined);
        } else {
          setItems([]);
          setPageData(undefined);
        }
      },
      [itemTypes, applicationValues],
  );

  const goToNode = (id: string | undefined) => {
    setNodeDetailId(id);
  };

  const exportReport = async () => {
    setIsLoading(true);
    const res = await getItemReportCsv(
        itemTypes.map((t) => t.value),
        applicationValues.map((a) => a.value),
        currentMaterializedViewDefinitionId,
    );
    if (res) {
      download(
          res,
          'CodeLogic Item Report.csv',
          'text/csv;charset=UTF-8',
      );
    } else {
      await Swal.fire('Unable to download CSV', 'Error retrieving CSV from the backend.  Please try again in 30 seconds.', 'error');
    }
    setIsLoading(false);
  };

  return (
    <>
      <MaterializedViewAlert dataAvailable={() => window.location.reload()}/>
      {currentMaterializedViewDefinitionId && !isLoading && (!applicationValues || applicationValues.length === 0) && !hasData && (
        <WorkingOnIt hadData={()=> setHasData(true)} gotData={() => setHasData(true)}/>
      )}
      {(hasData || !currentMaterializedViewDefinitionId) && <Layout title="Item Report" loading={isLoading}>
        <div className="reports-content">
          <div className="filter-cont">
            <AsyncSelect
              key={'type' + reload}
              defaultOptions
              cacheOptions
              isClearable
              isMulti
              className="filter-input"
              placeholder="Item Types"
              onChange={(selections: any) => {
                setItemTypes(selections);
              }}
              loadOptions={fetchItemTypes}
              value={itemTypes}
            />

            <AsyncSelect
              key={'app' + reload}
              defaultOptions
              cacheOptions
              isClearable
              isMulti
              className="filter-input"
              placeholder="Applications"
              onChange={(obj) => {
                setApplicationValues([...obj]);
              }}
              loadOptions={fetchApplications}
              value={applicationValues}
            />
            <Button
              color="primary"
              onClick={exportReport}
              disabled={!applicationValues || applicationValues.length === 0}
            >
              Export
            </Button>
          </div>
          <ListingPage
            columns={columns}
            data={items}
            pageData={pageData}
            fetchData={fetchReport}
          />
        </div>
        {nodeDetailId && (
          <NodeDetailModal
            isOpen={!!nodeDetailId}
            toggle={() => setNodeDetailId(null)}
            nodeId={nodeDetailId}
            goToNode={goToNode}
          />
        )}
      </Layout>}
    </>
  );
};
