import {useContext, useEffect, useState} from 'react';
import classnames from 'classnames';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import './App.css';
import {StompSessionProvider} from 'react-stomp-hooks';

import {
  cleanUpFeatureFlags,
  useLabelColors,
} from 'src/hooks';
import {THEMES} from 'src/constants';
import {
  ColorLegendContext,
  AuthContext,
  ThemeContext,
} from 'src/context';
import {
  Console,
  Header,
  Login,
} from 'src/components';
import {cookies} from 'src/api/cookies';
import {AUTHENTICATED_COOKIE_NAME, ROLES_COOKIE_NAME} from 'src/api/auth';
import {
  APP_ROOT,
  AZURE_AD_AUTH_CALLBACK_PATH,
} from 'src/routes';
import {AuthenticatedRoutes} from 'src/routes/AuthenticatedRoutes';

import {AzureAdCallback} from './components/Login/AzureAd';
import {UserRole} from './types';

function App() {
  const {theme} = useContext(ThemeContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [roles, setRoles] = useState<Array<UserRole>>(() => {
    const rolesCookie = cookies.get(ROLES_COOKIE_NAME);
    if (rolesCookie && Array.isArray(rolesCookie)) {
      return rolesCookie;
    } else {
      return [];
    }
  });
  const {colorLegend, getLegendEntry} = useLabelColors();
  useEffect(() => {
    THEMES.forEach((t) => {
      document.body.classList.remove(t);
    });

    if (theme) {
      document.body.classList.add(theme);
    }
  }, [theme]);

  useEffect(() => {
    cleanUpFeatureFlags();
  }, []);

  useEffect(() => {
    const authenticated = cookies.get(AUTHENTICATED_COOKIE_NAME);
    if (authenticated === 'true') setIsLoggedIn(true);
  }, []);

  return (
    <div
      className={classnames({
        [theme]: true,
      })}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
      data-test="component-app"
    >
      <Console />
      <AuthContext.Provider value={{isLoggedIn, setIsLoggedIn, roles, setRoles, hasRole: (userRole) => roles && roles.includes(userRole)}}>
        <ColorLegendContext.Provider value={{
          colorLegend,
          getLegendEntry,
        }}>
          <StompSessionProvider url={'/codelogic/server/ws'}>
            <Router>
              <Header />
              <div style={{
                height: 'calc(100% - var(--top-nav-height))',
                display: 'flex',
                flexFlow: 'row',
              }}>
                {isLoggedIn ? (
                  <AuthenticatedRoutes />
                ) : (
                  <Routes>
                    <Route path={AZURE_AD_AUTH_CALLBACK_PATH} element={<AzureAdCallback />} />
                    <Route path={`${APP_ROOT}/*`} element={<Login />} />
                  </Routes>
                )}
              </div>
            </Router>
          </StompSessionProvider>
        </ColorLegendContext.Provider>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
