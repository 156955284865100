import classnames from 'classnames';

import loaderGif from 'src/loader.gif';

import {Props} from './types';

import './styles.css';

export const Layout = ({title, options = null, loading = false, children, className}: Props) => (
  <div className={classnames({
    'loader-cont': true,
    [className || '']: !!className,
  })}>
    {title && (
      <div className="page-header col-12">
        <div className="page-title-controls">
          <h1 className="title">{title}</h1>
        </div>
        <div
          className="btn-cont">{options}</div>
      </div>
    )}
    <div className="page-content">{children}</div>
    {loading && (
      <div className="loader">
        <img src={loaderGif} alt="loading…"/>
      </div>
    )}
  </div>
);

