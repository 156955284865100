import {PropsWithChildren, createContext, useEffect} from 'react';

import {MaterializedViewDefinition, Workspace} from 'src/types';
import {useWorkspace, useMaterializedView, useMaterializedViewDefinition} from 'src/hooks';

type WorkspaceContextType = {
  currentWorkspace: Workspace | undefined,
  currentMaterializedViewDefinition: MaterializedViewDefinition | undefined,
  currentMaterializedView: string | undefined
  setCurrentWorkspace: (w: Workspace | undefined) => void,
  loading: boolean,
  initializeWorkspace: (id?: string) => Promise<void>
  initializeMaterializedViewDefinition: (id?: string) => Promise<void>
  setCurrentMaterializedViewDefinition: (_?: MaterializedViewDefinition) => void,
  setCurrentMaterializedView: (_?: string) => void,
  fetchLatestMaterializedView: (definitionId: string) => void,
};

const INITIAL_WORKSPACE_PROPS = {
  currentWorkspace: undefined,
  currentMaterializedViewDefinition: undefined,
  currentMaterializedView: undefined,
  loading: false,
  setCurrentWorkspace: () => {},
  initializeWorkspace: async () => {},
  initializeMaterializedViewDefinition: async () => {},
  setCurrentMaterializedViewDefinition: () => {},
  setCurrentMaterializedView: () => {},
  fetchLatestMaterializedView: () => {},
};

export const WorkspaceContext = createContext<WorkspaceContextType>(INITIAL_WORKSPACE_PROPS);

export const WorkspaceContextProvider = ({children}: PropsWithChildren) => {
  const {
    loading: loadingWorkspace,
    workspace: currentWorkspace,
    initializeWorkspace,
    setWorkspace:
      setCurrentWorkspace,
  } = useWorkspace();
  const {
    loading: loadingMaterializedViewDefinition,
    materializedViewDefinition: currentMaterializedViewDefinition,
    initializeMaterializedViewDefinition,
    setMaterializedViewDefinition: setCurrentMaterializedViewDefinition,
  } = useMaterializedViewDefinition(currentWorkspace);
  const {
    loading: loadingMaterializedView,
    materializedView: currentMaterializedView,
    setMaterializedView: setCurrentMaterializedView,
    fetchLatestMaterializedView,
  } = useMaterializedView(currentMaterializedViewDefinition);

  useEffect(() => {
    const defaultDefinition = currentWorkspace?.materializedViewDefinition;
    setCurrentMaterializedViewDefinition(defaultDefinition);
  }, [currentWorkspace]);

  const loading = loadingWorkspace || loadingMaterializedViewDefinition || loadingMaterializedView;

  return (
    <WorkspaceContext.Provider value={{
      currentWorkspace,
      currentMaterializedViewDefinition,
      currentMaterializedView,
      setCurrentWorkspace,
      setCurrentMaterializedViewDefinition,
      setCurrentMaterializedView,
      fetchLatestMaterializedView,
      initializeWorkspace,
      initializeMaterializedViewDefinition,
      loading,
    }}>
      {children}
    </WorkspaceContext.Provider>
  );
};
