import RSAsyncSelect from 'react-select/async';
import RSSelect, {FormatOptionLabelMeta} from 'react-select';
import identity from 'lodash/identity';

import type {StylesConfig} from 'react-select';

export const Select = <T, >({
  placeholder,
  onChange,
  options,
  value,
  getOptionLabel = identity,
  getOptionValue = identity,
  formatOptionLabel,
  styles = {},
}: {
  placeholder?: string,
  onChange: (_:T | undefined) => void,
  options: Array<T>,
  value?: T,
  getOptionLabel?: (_: T) => string,
  getOptionValue: (_: T) => string,
  formatOptionLabel?: ((data: T, formatOptionLabelMeta: FormatOptionLabelMeta<T>) => React.ReactNode)
  styles?: StylesConfig<T>,
}) => (
    <RSSelect<T>
      styles={{
        control: (provided) => ({
          ...provided,
          borderColor: 'var(--color-border--standard)',
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          backgroundColor: 'var(--color-border--standard)',
        }),
        ...styles,
      }}
      isMulti={false}
      placeholder={placeholder}
      onChange={(value) => onChange(value || undefined)}
      value={value}
      formatOptionLabel={formatOptionLabel}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      options={options}
    />
  );

export const AsyncSelect = <T, >({
  placeholder,
  onChange,
  loadOptions,
  options = [],
  value,
  getOptionLabel = identity,
  getOptionValue = identity,
  formatOptionLabel,
  styles = {},
}: {
  placeholder?: string,
  onChange: (_:T | undefined) => void,
  options?: Array<T>,
  value?: T,
  getOptionLabel?: (_: T) => string,
  getOptionValue: (_: T) => string,
  formatOptionLabel?: ((data: T, formatOptionLabelMeta: FormatOptionLabelMeta<T>) => React.ReactNode)
  loadOptions?: (input: string) => Promise<Array<T>>,
  styles?: StylesConfig<T>,
}) => (
    <RSAsyncSelect<T>
      styles={{
        control: (provided) => ({
          ...provided,
          borderColor: 'var(--color-border--standard)',
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          backgroundColor: 'var(--color-border--standard)',
        }),
        ...styles,
      }}
      defaultOptions
      cacheOptions
      isMulti={false}
      placeholder={placeholder}
      onChange={(value) => onChange(value || undefined)}
      value={value}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      formatOptionLabel={formatOptionLabel}
      loadOptions={(input) => loadOptions ? loadOptions(input) : Promise.resolve(options)}
    />
  );

export const AsyncMultiSelect = <T, >({
  placeholder,
  onChange,
  loadOptions,
  options = [],
  value = [],
  getOptionLabel = identity,
  getOptionValue = identity,
  formatOptionLabel,
  styles = {},
}: {
  placeholder?: string,
  onChange: (_: Array<T> | undefined) => void,
  options?: Array<T>,
  value?: Array<T>,
  getOptionLabel?: (_: T) => string,
  getOptionValue: (_: T) => string,
  formatOptionLabel?: ((data: T, formatOptionLabelMeta: FormatOptionLabelMeta<T>) => React.ReactNode)
  loadOptions?: (input: string) => Promise<Array<T>>,
  styles?: StylesConfig<T>,
}) => (
    <RSAsyncSelect<T, true>
      styles={{
        control: (provided) => ({
          ...provided,
          borderColor: 'var(--color-border--standard)',
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          backgroundColor: 'var(--color-border--standard)',
        }),
        ...styles,
      }}
      defaultOptions
      cacheOptions
      isMulti={true}
      placeholder={placeholder}
      onChange={(value) => onChange(value as Array<T> /* MultiValue<T> is just readonly T[] */)}
      options={options}
      value={value}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      formatOptionLabel={formatOptionLabel}
      loadOptions={(input) => loadOptions ? loadOptions(input) : Promise.resolve(options)}
    />
  );

export const MultiSelect = <T, >({
  placeholder,
  onChange,
  options,
  value = [],
  getOptionLabel = identity,
  getOptionValue = identity,
  formatOptionLabel,
  styles = {},
}: {
  placeholder?: string,
  onChange: (_:Array<T> | undefined) => void,
  options?: Array<T>,
  value?: Array<T>,
  getOptionLabel?: (_: T) => string,
  getOptionValue: (_: T) => string,
  formatOptionLabel?: ((data: T, formatOptionLabelMeta: FormatOptionLabelMeta<T>) => React.ReactNode)
  styles?: StylesConfig<T>,
}) => (
    <RSSelect<T, true>
      styles={{
        control: (provided) => ({
          ...provided,
          borderColor: 'var(--color-border--standard)',
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          backgroundColor: 'var(--color-border--standard)',
        }),
        ...styles,
      }}
      isMulti={true}

      placeholder={placeholder}
      onChange={(value) => onChange(value as Array<T>) /* MultiValue<T> is just readonly T[] */}
      options={options}
      value={value}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      formatOptionLabel={formatOptionLabel}
    />
  );
