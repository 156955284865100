import {TabPane} from 'reactstrap';
import {IoExtensionPuzzleSharp, IoLogoDocker, IoLogoJavascript} from 'react-icons/io5';
import uniqBy from 'lodash/uniqBy';

import {CODELOGIC_API_BASE_URL} from 'src/config';

import {CATEGORY_ORDER, CATEGORY_METADATA_MAP} from './constants';
import {AgentImage} from './components';
import {Download} from './types';
import {AgentDownload} from './AgentDownload';
import {AgentCommandGenerator} from './AgentCommandGenerator';

import './styles.css';

const DOWNLOAD_BASE_PATH = `${CODELOGIC_API_BASE_URL}/packages`;

const DownloadLink = ({url}: {url: string}) => (
  <a
    download
    className="DownloadLink"
    href={DOWNLOAD_BASE_PATH + '/' + url}
  >
    Download
  </a>
);

const DOWNLOADS: Record<typeof CATEGORY_ORDER[number], Array<Download>> = {
  miscellaneous: [{
    agentName: 'Rich Client',
    icon: <AgentImage url='cytoscape_icon_bg_transparent.png' alt="Rich Client Download Icon" />,
    renderAction: <DownloadLink url='codelogic-client.jar' />,
  }, {
    agentName: 'NodeJSCape Dynamic (JavaScript Agent)',
    icon: <IoLogoJavascript size="3em" />,
    renderAction: <DownloadLink url='nodejscape-dynamic.tgz' />,
  }],
  docker: [{
    agentName: 'Java Agent Docker Image',
    icon: <IoLogoDocker size="3em" />,
    renderAction: <AgentCommandGenerator type="java" />,
  }, {
    agentName: 'SQL Agent Docker Image',
    icon: <IoLogoDocker size="3em" />,
    renderAction: <AgentCommandGenerator type="sql" />,
  }, {
    agentName: '.NET Agent Docker Image',
    icon: <IoLogoDocker size="3em" />,
    renderAction: <AgentCommandGenerator type="dotnet" />,
  }, {
    agentName: 'JavaScript Agent Docker Image',
    icon: <IoLogoDocker size="3em" />,
    renderAction: <AgentCommandGenerator type="javascript" />,
  }],

  plugins: [{
    agentName: 'Plugin for IntelliJ IDEA',
    icon: <AgentImage url='jetbrains_icon_bg_transparent.png' alt="Plugin for IntelliJ IDEA Download Icon" />,
    renderAction: <DownloadLink url='codelogic-intellij-plugin.zip' />,
    disclaimer: 'Copyright © 2000-2022 JetBrains s.r.o. JetBrains and the JetBrains logo are registered trademarks of JetBrains s.r.o.',
  }, {
    agentName: 'Extension for Microsoft Visual Studio 2019',
    icon: <IoExtensionPuzzleSharp size="3em" />,
    renderAction: <DownloadLink url='codelogic-visual-studio-plugin-2019.vsix' />,
    disclaimer: 'Microsoft and Microsoft Visual Studio are trademarks of the Microsoft group of companies.',
  }, {
    agentName: 'Extension for Microsoft Visual Studio 2022',
    icon: <IoExtensionPuzzleSharp size="3em" />,
    renderAction: <DownloadLink url='codelogic-visual-studio-plugin-2022.vsix' />,
    disclaimer: 'Microsoft and Microsoft Visual Studio are trademarks of the Microsoft group of companies.',
  }, {
    agentName: 'Extension for Microsoft Visual Studio Code',
    icon: <IoExtensionPuzzleSharp size="3em" />,
    renderAction: <DownloadLink url='codelogic-vscode-plugin.vsix' />,
    disclaimer: 'Visual Studio Code, VS Code, and the Visual Studio Code icon are trademarks of Microsoft Corporation. All rights reserved.',
  }],
  windows: [{
    agentName: 'Windows Java Agent',
    icon: <AgentImage url='generic_icon_bg_transparent.png' alt="Windows Java Agent Download Icon" />,
    renderAction: <DownloadLink url='codelogic-java.msi' />,
  }, {
    agentName: 'Windows SQL Agent',
    icon: <AgentImage url='generic_icon_bg_transparent.png' alt="Windows SQL Agent Download Icon" />,
    renderAction: <DownloadLink url='codelogic-sql.msi' />,
  }, {
    agentName: 'Windows .NET Agent',
    icon: <AgentImage url='generic_icon_bg_transparent.png' alt="Windows .NET Agent Download Icon" />,
    renderAction: <DownloadLink url='netcape-installer.exe' />,
  }],
};

export const Store = () => (
  <TabPane className="Store-tabpane">
    <div className="Store-list-container">
      <div>
        {CATEGORY_ORDER.map((category) => {
          const list = DOWNLOADS[category];
          if (list.length === 0) {
            return null;
          }

          return (
            <div className="Store-list-category" key={category} >
              <h2>{CATEGORY_METADATA_MAP[category].sectionLabel}</h2>
              <div className="Store-list">
                {list.map((args) => {
                  return (
                    <AgentDownload key={args.agentName} {...args}>
                      {args.renderAction}
                    </AgentDownload>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div className="Store-disclaimers">
        {uniqBy(Object.values(DOWNLOADS).flat().filter((d) => !!d.disclaimer), ({disclaimer}) => disclaimer).map(({disclaimer}) => (<p key={disclaimer}>{disclaimer}</p>))}
      </div>
    </div>
  </TabPane>
);
